
import {IonIcon, IonItem, IonLabel, IonCard, loadingController, IonSpinner} from '@ionic/vue';
import { useRoute, useRouter } from "vue-router";
import {ref, computed, defineComponent, watch} from 'vue';
import {
  JourneySet,
  deleteJourneySet,
  getMediaItem,
  JourneySetToDisplay, getLocalManifestJourneySetIds,
} from '@/storage/DataStorage';
import { chevronBack, help, ellipsisVertical, leaf, addSharp, syncCircle } from "ionicons/icons";
import translate, {getTranslatedNumber} from "./translate";
import {createLoadSpinner, saveApp, saveJourneySetToDevice} from '@/services/ShareService';
import { useStore } from "vuex";
import { AlertBuilder } from "@/utilities/AlertBuilder";
import {ActionSheetBuilder} from "@/utilities/ActionSheetBuilder";
import {isConnectedToInternet} from "@/utilities/Network";
import {getImageDataUri} from "@/utilities/Compatibility";
import {downloadJourneySetWithSpinner, getDownloadableJourneySets} from '@/services/AWSService';
import {connectToInternetAlert} from '@/utilities/Alerts';
import {ConnectionStatus, Network} from '@capacitor/network';

export default defineComponent({
    name: "JourneySets",
    components: {
        IonSpinner,
        IonIcon,
        IonItem,
        IonLabel,
        IonCard,
    },
    data() {
      return {
        active: false
      };
    },
    computed: {
      latestID(): string {
        return this.$store.state.latestBackgroundId;
      }
    },
    watch: {
      backgroundWorkerProgress: async function () {
        if(this.active && this.journeySets.findIndex(j => j.id === this.latestID) >= 0) {
          await this.setJourneySets();
        }
      } 
    },
    async ionViewWillEnter() {
        this.loaded = false;
        this.active = true;
        const quadrant = await this.$store.getters.getQuadrant(this.currentQuadrantId);
        if (!quadrant) {
            await this.$router.push({ name: `fields` });
            return;
        }
        const useAlternateQuadrantInfo = (quadrant.id === 'jf5' || quadrant.id === 'jf6') && this.$store.getters.showAllQuadrants();
        this.subHeaderText = useAlternateQuadrantInfo && quadrant.altName ? quadrant.altName : quadrant.name;
        const headerImage = await getMediaItem(quadrant.iconId);
        this.$store.dispatch("setHeaderImage", getImageDataUri(headerImage.content));
        this.$store.dispatch("setHeaderTitle", "");
        await this.setDownloadableJourneySets();
        await this.setJourneySets();
    },
    ionViewWillLeave() {
      this.active = false;
    },
    setup() {
        const { t } = translate();
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const journeySets = ref([] as JourneySetToDisplay[]);
        const downloadableSets = ref([] as JourneySetToDisplay[]);
        const currentQuadrantId = computed(() => route.params.fieldId as string);
        const subHeaderText = ref("" as string);
        const messages = computed(() => store.getters.getLocaleMessages());
        const showNoJourneySets = ref(false);
        const loaded = ref(false);
        const backgroundWorkerProgress = computed(() => store.getters.getBackgroundProgress());
        const localJourneySetIds = ref([] as string[]);


      const isSecure = computed(() => store.getters.isSecure());
      const isSecureInternetRequest = ref(false);
      const networkConnected = ref(true);
      
      //Don't check the internet status unless the user specifically requests it on the secure version
      //Use this for UI only via showNoInternet. DO NOT USE hasInternet FOR INTERNET CHECK, USE checkInternet & networkConnected instead
      const hasInternet = computed( () => {
        if(!isSecure.value || isSecureInternetRequest.value) {
          return networkConnected.value;
        }
        //UI should behave as though there is internet in the secure app until it is requested and then it can be updated.
        //Hoping for a 'Go Online' user option that can be moved into the store to replace this.
        return isSecure.value && !isSecureInternetRequest.value;
      });
      
      //Silent internet check for background processes, pop-up for user actions
      async function checkInternet(showAlert = true, status: boolean | null = null) {
        if(isSecure.value && !isSecureInternetRequest.value) {
          networkConnected.value = false;
        } else if( status !== null) {
          networkConnected.value = status;
        } else {
          networkConnected.value = await isConnectedToInternet(showAlert);
        }
      }
      
        Network.addListener('networkStatusChange', async (status: ConnectionStatus) => {
          await checkInternet(false, status.connected);
        });
      
        async function setDownloadableJourneySets() {
            await checkInternet(false);
            downloadableSets.value = networkConnected.value ? await getDownloadableJourneySets(currentQuadrantId.value) : [];
        }
        
        function removeDuplicates(journeySetsFromDB: JourneySetToDisplay[]) {
          const seen = new Map<string, boolean | undefined>();
          const out: JourneySetToDisplay[] = [];
          for (const journeySet of journeySetsFromDB) {
            const journeySetId = journeySet.id.toUpperCase();
            if (!seen.has(journeySetId)) {
              seen.set(journeySetId, journeySet.alreadyDownloaded);
              out.push(journeySet);
            } else if (journeySet.alreadyDownloaded) {
              // Merge and remove duplicates favoring whatever's downloaded
              const replaceIndex = out.findIndex(s => s.id.localeCompare(journeySetId, 'en', { sensitivity: 'base' }) === 0);
                out[replaceIndex] = journeySet;
              }
            }
            return out;

          }

        const loadJourneySets = async (): Promise<JourneySetToDisplay[]> => {
            const loading = await createLoadSpinner();
            await loading.present();
            
            const journeySetsFromDB = removeDuplicates((await getJourneySetsFromDB()).filter((set: JourneySet) => !set.unavailableToDownload));
            const setIdsInDB = journeySetsFromDB.map((set: JourneySet) => set.id.toUpperCase());
            
            if(networkConnected.value) {
              const noLongerAvailable = journeySetsFromDB.filter((dbSet: JourneySet) => !dbSet.alreadyDownloaded &&
                  downloadableSets.value.every((downSet: JourneySet) => downSet.id !== dbSet.id));
              noLongerAvailable.forEach((toRemove: JourneySet) => deleteJourneySet(toRemove.id, true, true));
            }
            const newDownloadableSets = downloadableSets.value.filter(set => !setIdsInDB.includes(set.id.toUpperCase()));
          
            const setsAvailableToDownload = newDownloadableSets.map(set => {
                set.imageValue = getImageDataUri(set.icon);
                return set;
            });
            const journeySetsToDisplay = [...journeySetsFromDB, ...setsAvailableToDownload].sort((a: JourneySet, b: JourneySet) => a.order - b.order);
            showNoJourneySets.value = journeySetsToDisplay.length === 0;
            
            await loading.dismiss();
            return journeySetsToDisplay;
        };
        
        async function getJourneySetsFromDB() {
            const journeySets = await store.getters.getJourneySets(currentQuadrantId.value);
            return await Promise.all(journeySets.map(async (journeySet: JourneySet) => {
                const journeySetToDisplay = journeySet as JourneySetToDisplay;
                const { content } = await getMediaItem(journeySet.iconId);
                journeySetToDisplay.imageValue = getImageDataUri(content);
                
                //legacy support for journeySets downloaded before the flag was added
                if(journeySetToDisplay.alreadyDownloaded === undefined || journeySetToDisplay.alreadyDownloaded === null) {
                  journeySetToDisplay.alreadyDownloaded = true;
                }
                
                if (downloadableSets.value.length > 0) {
                    const correspondingDownloadableSet = downloadableSets.value.find(set => set.id.localeCompare(journeySetToDisplay.id,'en', { sensitivity: 'base' }) === 0);
                    journeySetToDisplay.updateAvailable = Boolean(correspondingDownloadableSet &&
                        ((!journeySetToDisplay.published && correspondingDownloadableSet.published) ||
                            (journeySetToDisplay.published && correspondingDownloadableSet.published &&
                                new Date(journeySetToDisplay.published).setSeconds(0, 0) < new Date(correspondingDownloadableSet.published).setSeconds(0, 0))));
                  } else {
                    journeySetToDisplay.updateAvailable = false;
                  }
                  return journeySetToDisplay;
              })) as JourneySetToDisplay[];
        }
      
        const isDownloadableOfflineCheck = async (id: string): Promise<boolean> => {
          if((!isSecure.value || isSecureInternetRequest.value) || (downloadableSets.value && downloadableSets.value.length > 0)) {
            return false;
          }
  
          if(localJourneySetIds.value.length == 0) {
            localJourneySetIds.value = await getLocalManifestJourneySetIds();
          }
          return localJourneySetIds.value.findIndex(l => l.localeCompare(id,'en', { sensitivity: 'base' }) === 0) !== -1;
        }

        const openDeleteAlert = async (id: string) => {
            const alert = new AlertBuilder('custom-alert',
                `<p>${t("messages.application.removingJourneySet")}</p>
                <p>${t("messages.application.wishToContinue")}</p>`
            ).addNoButton().addYesButton(async () => {
                const journeyIndex = journeySets.value.findIndex((journeySet) => journeySet.id === id)
                const journeySet = journeySets.value[journeyIndex];
       
                const isDownloadable = journeySetDownloadableIndex(journeySet.id);
                const softDelete = isDownloadable !== -1 || await isDownloadableOfflineCheck(journeySet.id);
                if (softDelete) {
                    journeySet.alreadyDownloaded = false;
                    journeySet.deleted = true;
                    journeySets.value.splice(journeyIndex, 1, journeySet);
                } else {
                    journeySets.value.splice(journeyIndex, 1);
                }
                await deleteJourneySet(id, softDelete);
            });
            await alert.present();
        };
      
        function journeySetDownloadableIndex(id: string): number {
          return downloadableSets.value.findIndex(set => set.id.localeCompare(id,'en', { sensitivity: 'base' }) === 0);
        }
        
        const presentActionSheet = async (journeySet: JourneySetToDisplay) => {
          if (isJourneySetLoading(journeySet)) {
            return;
          }
            try {
                const name = journeySet.name;
                const actionSheetBuilder = new ActionSheetBuilder(name);
                if (store.getters.isPwa()) {
                     actionSheetBuilder
                         .addSaveJourneySet(async () => await saveJourneySetToDevice(journeySet.id))
                         .addSaveToDevice(async () => await saveApp())
                } else if (store.getters.platform() === "ios" || messages.value.buildConfigs.config.appStoreBuild === 'true') {
                    actionSheetBuilder.addShareJourneySet(name, journeySet.id).addAppOptions(true);
                } else if (messages.value.buildConfigs.config.appStoreBuild === 'false') {
                    actionSheetBuilder.addShareJourneySet(name, journeySet.id).addAppOptions(false);
                }

                if (journeySet.updateAvailable) {
                    actionSheetBuilder.addUpdate(async () => await download(journeySet), syncCircle)
                }

                actionSheetBuilder.addDeleteJourneySet(async () => {
                    await openDeleteAlert(journeySet.id);
                });
                

                actionSheetBuilder.addClose();
                const actionSheet = await actionSheetBuilder.create();
                await actionSheet.present();
            }
            catch {
                return;
            }
        };

        const routeToJourney = (journeySetId: string) => {
            router.push({ name: 'journeys', params: { fieldId: currentQuadrantId.value, journeySetId: journeySetId } });
        };

        /*
          Online download. requires internet connection to S3
          Journey sets must be present in S3
         */
        async function download(journeySet: JourneySetToDisplay) { 
            await checkInternet();
            if (!networkConnected.value || journeySetDownloadableIndex(journeySet.id) < 0) {
                return;
            }

            if (journeySet.updateAvailable) {
                await deleteJourneySet(journeySet.id);
            }
            await downloadJourneySetWithSpinner(journeySet.id, currentQuadrantId.value as string);
            const downloadedJourneySet = journeySets.value.findIndex(js => js.id.localeCompare(journeySet.id, 'en', { sensitivity: 'base' }) === 0);
            if (downloadedJourneySet != -1) {
                journeySet.alreadyDownloaded = true;
                journeySet.updateAvailable = false;
                journeySets.value.splice(downloadedJourneySet,1,journeySet);
            }
        }

        async function clickJourney(journeySet: JourneySetToDisplay) {
            if (isJourneySetLoading(journeySet)) {
              return;
            }
            if (store.getters.isSecure() && !journeySet.alreadyDownloaded) {
                const shouldDisplayInternetAlert = await connectToInternetAlert(async () => {
                    isSecureInternetRequest.value = true;
                    await setDownloadableJourneySets();
                    await download(journeySet);
                    isSecureInternetRequest.value = false;
                });
                if (!shouldDisplayInternetAlert) {
                    await download(journeySet);
                }
            } else if(!journeySet.alreadyDownloaded) {
                await download(journeySet);
            } else {
                routeToJourney(journeySet.id);
            }
        }
        
        async function setJourneySets() {
            loaded.value = false;
            journeySets.value = await loadJourneySets();
            loaded.value = true;
        }
        
        function isJourneySetLoading(journeySet: JourneySetToDisplay): boolean {
          return !journeySet.alreadyDownloaded && journeySet.isDefaultSet && !journeySet.deleted;
        }
        
        //Loading is always true for secure as the files are local, app versions reflect internet w/o prompt
        function showNoInternet(journeySet: JourneySetToDisplay) {
          return isJourneySetLoading(journeySet) && !(isSecure.value || hasInternet.value);
        }        
        
        function getJourneyColor(journeySet: JourneySetToDisplay): string {
          return journeySet.alreadyDownloaded ? '' : 'not-downloaded';
        }

        return {
            subHeaderText,
            journeySets,
            routeToJourney,
            chevronBack,
            help,
            ellipsisVertical,
            leaf,
            addSharp,
            presentActionSheet,
            t,
            currentQuadrantId,
            loadJourneySets,
            returnRoute:`/fields`,
            getTranslatedNumber,
            messages,
            showNoJourneySets,
            clickJourney,
            syncCircle,
            setJourneySets,
            loaded,
            setDownloadableJourneySets,
            isJourneySetLoading,
            getJourneyColor,
            downloadableSets,
            backgroundWorkerProgress,
            showNoInternet
        };
    },
});
