import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e91cd6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 3,
  src: "/assets/img/wifi-x.svg",
  class: "download-image",
  slot: "end"
}
const _hoisted_4 = {
  key: 5,
  src: "/assets/img/download.svg",
  class: "download-image",
  slot: "end"
}
const _hoisted_5 = {
  key: 1,
  class: "no-sets"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    returnRoute: _ctx.returnRoute,
    subHeaderText: _ctx.subHeaderText
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.journeySets, (journeySet) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  key: journeySet.id,
                  class: _normalizeClass(["journey-card", _ctx.getJourneyColor(journeySet)]),
                  onClick: ($event: any) => (_ctx.clickJourney(journeySet))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      lines: "none",
                      class: _normalizeClass(_ctx.getJourneyColor(journeySet))
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isJourneySetLoading(journeySet))
                          ? (_openBlock(), _createBlock(_component_ion_spinner, {
                              key: 0,
                              class: "loading-spinner",
                              slot: "start"
                            }))
                          : (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 1,
                              src: journeySet.imageValue,
                              class: "journey-set-image",
                              slot: "start"
                            }, null, 8, ["src"])),
                        _createElementVNode("div", null, [
                          _createVNode(_component_ion_label, {
                            class: _normalizeClass(["journey-title ion-text-wrap", {loading: _ctx.isJourneySetLoading(journeySet)}])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.isJourneySetLoading(journeySet) ? _ctx.messages.application.journeySetLoading : '') + _toDisplayString(journeySet.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"]),
                          _createVNode(_component_ion_label, { class: "number-of-journeys" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslatedNumber(journeySet.numberOfJourneys)) + " " + _toDisplayString(parseInt(journeySet.numberOfJourneys, 10) === 1 ? _ctx.messages.application.journeysSingle : _ctx.messages.application.journeys), 1)
                            ]),
                            _: 2
                          }, 1024),
                          (journeySet.updateAvailable)
                            ? (_openBlock(), _createBlock(_component_ion_label, {
                                key: 0,
                                class: "update"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.messages.application.updateAvailable), 1)
                                ]),
                                _: 1
                              }))
                            : (_ctx.showNoInternet(journeySet))
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 1,
                                  class: "update"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.messages.application.noInternetJourneysets), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                        ]),
                        (journeySet.alreadyDownloaded)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 2,
                              icon: _ctx.ellipsisVertical,
                              class: "share-image",
                              slot: "end",
                              onClick: _withModifiers(($event: any) => (_ctx.presentActionSheet(journeySet)), ["stop","prevent"])
                            }, null, 8, ["icon", "onClick"]))
                          : (_ctx.showNoInternet(journeySet))
                            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                            : (_ctx.isJourneySetLoading(journeySet))
                              ? (_openBlock(), _createBlock(_component_ion_icon, {
                                  key: 4,
                                  icon: _ctx.ellipsisVertical,
                                  class: "share-image",
                                  slot: "end"
                                }, null, 8, ["icon"]))
                              : (_openBlock(), _createElementBlock("img", _hoisted_4))
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.showNoJourneySets)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.messages.application.noJourneySets), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["returnRoute", "subHeaderText"]))
}